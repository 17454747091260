@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-datepicker/dist/react-datepicker.css";
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500;600;700&family=Poppins:wght@500;700;800&display=swap');

.brand-heading {
  font-family: "Poppins", "Avenir Next", "ui-sans-serif", "system-ui";
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
}
.brand-subHeading {
  font-family: "Poppins", "Avenir Next", "ui-sans-serif", "system-ui";
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.045em;
}
.brand-text {
  font-family: "Poppins", "Avenir Next", "ui-sans-serif", "system-ui";
}
.brand-tertiaryHeading {
  font-family: "Poppins", "Avenir Next", "ui-sans-serif", "system-ui";
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.055em;
}
.poppins {
  font-family: "Poppins", "ui-sans-serif", "system-ui";
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
}
.poppinsBold {
  font-family: "Poppins", "ui-sans-serif", "system-ui";
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
}
.mulish {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
}

.dark .react-select-container .react-select__control {
    background-color: #e4e4e7;
    transition: none;
  }

/* aboutGolf Brand colors */
.brand-yellow {
  color: #f1d448;
}
.brand-dark-gray {
  color: #1f231e;
}
.brand-floral-white {
  color: #f9faf2;
}
.brand-light-green {
  color: #8aa899;
}
.brand-dark-green {
  color: #5c6e58;
}
.brand-dark-cream {
  color: #f2f2e6;
}

.bg-green {
  background-color: #5c6e58;
}

/* leagues */
.league-event {
  background: #50F148;
}
.league-team {
  background: #f3eabf;
}
.league-individual {
  background: #f2f2e6;
}
/* pre-flight */
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

/* Logo classes */
.logo {
  max-width: 100px;
  height: auto;
}
.logo-small {
  max-width: 60px;
}
.logo-medium {
  height: 32px;
  max-width: inherit;
}
.logo-large {
  max-width: 200px;
}
.brand-dark-gray {
  color: #1f231f
}
.brand-light-green {
  color: #5c6e58
}

.background-gray {
  background-color: #f8f8f8;
}
.background-brand-green {
  background: #5c6e58;
}
.background-floral-white {
  background: #f9faf2;
}
.background-black {
  background-color: #1f231f;
}
.bg-brandYellow {
  background: #f1d448;
}
.border-brandYellow {
  border-color: #f1d448;
}
.bg-dark-cream {
  background: #f2f2e6;
}
.text-brandYellow,
.dark .text-brandYellow {
  color: #f1d448;
}
.greyText {
  color: #868686;
}

.menu a,
.heading {
  font-family: Mulish, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}
#menu a.active:before {
  content: '';
  background-color: #f2d349;
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 6px;
  z-index: -1;
}
.topfive {
  padding-top: 3px;
}

.triangle {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width:  0 10px 15px 10px;
  border-color: transparent transparent #9CA3AF transparent;
  transform: rotate(0deg);
}
.triangle div {
  margin-left: -4px;
}
.sidebarCurve {
  width: 12px;
}
.marginTop12 {
  margin-top: 9px;
}

body {
  margin: 0;
  font-family: "Mulish", "Avenir Next", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  -webkit-text-stroke: .025em rgba(51,51,51,0.50);
  -webkit-font-smoothing: antialiased;
-moz-font-smoothing: antialiased;
-o-font-smoothing: antialiased;
}

@media print {
  #innerModal header,
  #innerModal footer {
    display: none;
  }
}

/* Hide vertical scrollbars on trays */
#SimulatorReservationTray,
#createQueueItemTray,
#createEventTray,
#onlineEventsTray {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

#SimulatorReservationTray::-webkit-scrollbar,
#createQueueItemTray::-webkit-scrollbar,
#createEventTray::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

svg {
  display: inline;
}
.opacity-25 {
  opacity: 0.25;
}
.simulatorOffMessage {
  background-color: #71717a;
  padding: 5px 20px;
  border-radius: 20px;
}

/* React Select className prefix styles */
.dark #startGameAddPlayers [class*="-Input"], 
.dark #lockerUserEditFormSearchField [class*="-Input"],
.dark #lockerUserEditFormSearchField input, 
.dark .reactSelectSearchField [class*="-Input"],
.dark .react-select__value-container,
.dark .react-select__value-container [class*="-ValueContainer"],
.dark #reactSelectSearch,
.dark #startGameAddPlayers,
.dark #lockerUserEditFormSearchField,
.dark #react-select-3-input {
  color: #fff !important;
}

.addToTeam {
  color: #5c6e58 !important;
}
.dark .addToTeam {
  color: #f1d448 !important;
}
/* ToolTip Theme */
.tooltipTheme,
.__react_component_tooltip {
  background-color: white !important;
  color: #27272a !important;
  font-family: "Poppins", "Avenir Next", "ui-sans-serif", "system-ui";
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  border-radius: 8px !important;
  border: 1px solid #CCCCCC !important;
}
.__react_component_tooltip.place-bottom::after {
  background-color: white !important;
  border: 1px solid #CCCCCC !important;
  top: -5px !important;
}

.dark .__react_component_tooltip.place-bottom::after {
  background-color: #52525b !important;
  border: 1px solid #18181b !important;
  top: -5px !important;
}
/* Rex Reservation Overrides */
.css-1h6b9u7, 
.css-17ukx4e, 
.css-s9fg0m {
  background-color: #f8f8f8 !important;
}
.css-s9fg0m {
  display: none !important;
}
.css-zecy3o-MuiButtonBase-root-MuiButton-root {
  background-color: #5c6e58 !important;
  color: #fff !important;
}
.css-1uveaag {
  color: #5c6e58 !important;
}

/* Scorecards */
.albatross {
  border: 3px solid rgb(209, 213, 219)  
}
.eagle {
  box-shadow: rgb(209, 213, 219) 0px 0px 0px 1px inset, white 0px 0px 0px 2px inset, rgb(209, 213, 219) 0px 0px 0px 3px inset
}
.birdie {
  border: 1px solid rgb(209, 213, 219)
}
.bogey {
  border: 1px solid #d1d5db;
  border-radius: 5px;
}
.doubleBogey {
  box-shadow: rgb(209, 213, 219) 0px 0px 0px 1px inset, white 0px 0px 0px 2px inset, rgb(209, 213, 219) 0px 0px 0px 3px inset;
  border-radius: 5px;
}

/* dark mode override for react select and numbers */
.dark [class*="-control"],
.dark [class*="-option"],
.dark [class*="-menu"],
.dark [type="number"],
.dark .rmsc .dropdown-container,
.dark .rmsc .panel-content li, 
.dark .rmsc .select-item:hover,
.dark .rmsc .select-item:focus,
.dark .rmsc .select-item:active,
.dark .rmsc .select-item {
  background-color: #18181b;
  color: #f9faf2;

}
.dark .rmsc .select-item.selected {
  background-color:#5c6e58
}
.dark [class*="-singleValue"],
.dark [class*="-placeholder"] {
  color: #f9faf2;
}
.dark [class*="-ValueContainer"] {
  color: #fff;
}

/* Menu */
#mainMenu svg, 
#mobileMenu svg {
  width: 20px;
  height: 20px;
}
#mainMenu svg.external, 
#mobileMenu svg.external {
  width: 15px;
  height: 15px;
}
#mainMenu li a:hover > svg,
#mainMenu li button:hover > svg,
#mobileMenu li a:hover > svg,
#mobileMenu li button:hover > svg {
  fill: #fff;
  color: #fff;
}

#mainMenu li a.active > svg,
#mainMenu li button.active > svg ,
#mobileMenu li a.active > svg,
#mobileMenu li button.active > svg {
  fill: #4c4d4d !important;
  color: #4c4d4d !important;
}

.absPosX {
  top: 132px;
  right: 20px;
  height: 44px;
  width: 44px;
}

.PhoneInputCountry {
  padding: 0 10px;
  border-right: 1px solid #ccc;
}
.PhoneInputInput {
  border-width: 0px;
  font-size: 14px;
  padding: 5px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dark .PhoneInputInput {
  color: #fff;
  background-color: #18181b;
  border-color: #fff;
}
.dark .PhoneInputCountrySelect, 
.dark .PhoneInputCountrySelect option {
  color: #fff;
  background-color: #18181b;
}
.negativeMarginOutside {
  margin-right: -50px;
  margin-left: -50px;
}
.negativeMarginInside {
  padding-right: 50px;
  padding-left: 50px;
}
#scorecardLayoutContainer {
  max-width: 650px;
}
.strokesGiven {
  font-size: 8px;
  color: #000;
  position: absolute;
  top: -3px;
  right: -9px;
  padding: 0 3px;
  line-height: 10px;
}

.leagueCard:hover .leagueCardEditDelete {
  display: flex;
}
.leagueRoundCard:hover .leagueRoundCardEditDelete {
  display: flex;
}

.react-datepicker {
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__month-container {
  font-family: "Poppins", "Avenir Next", "ui-sans-serif", "system-ui";
}
.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  position: relative;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  padding: 10px 0;
  color: #000;
}
.react-datepicker__navigation {
  top: 20px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #f1d448 !important;
  color: #000 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #5c6e58 !important;
  color: #fff !important;
}
.react-datepicker__time-container {
  border-left: 1px solid #ccc;
  width: 100px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px;
  margin-left: 0px;
}

.draggedItemClass {
  background-color: #f1d448;
  color: #000;
  visibility: visible !important;
  opacity: 100 !important;
}