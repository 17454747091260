@primary-color: rgb(92, 110, 88);
@alt-color: rgb(245, 245, 245);

.inputContainer {
  position: relative;

  //make  radio input invisible + stretch to fill container
  .radioButton {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
  }

  //default tile styles
  .radioTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: transform 300ms ease;
  }
  .icon svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  .radioTileLabel {
    text-align: center;
  }

  //active tile styles
  .radioButton:checked + .radioTile {
    background-color: @primary-color;
    border: 2px solid @primary-color;
    color: white;
    transform: scale(1.05, 1.05);

    .icon i, .icon svg {
      fill: white;
      color: white;
      background-color: @primary-color;
    }
    .radioTileLabel {
      color: white;
      background-color: @primary-color;
    }
  }
}
