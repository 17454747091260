.container {
  align-items: center;
  /*
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(253, 203, 50, 1) 0%,
    rgba(244, 56, 98, 1) 100.2%
  );
  */
  border-radius: 28px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s;
}
.handle {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: grid;
  justify-items: center;
  overflow: hidden;
}
.icon {
  color: #f88748;
}
/*
[data-darkmode="true"] {
  background-color: #52527a;
}
[data-darkmode="true"].container {
  background-image: linear-gradient(
    109.8deg,
    rgba(62, 5, 116, 1) -5.2%,
    rgba(41, 14, 151, 1) -5.2%,
    rgba(216, 68, 148, 1) 103.3%
  );
}
*/
[data-darkmode="true"].icon,
[data-darkmode="true"] i {
  color: #d8b4fe;
}
