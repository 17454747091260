.st0 {
  clip-path: url(#SVGID_2);
}
.st1 {
  fill: none;
  stroke-width: 132;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  clip-path: url(#SVGID_4);
}
.st3 {
  fill: none;
  stroke-width: 550;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

svg {
  max-width: 340px;
  margin: 0 auto;
  background: transparent;
}

@keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}

.sub_two line:nth-of-type(1) {
  stroke-dasharray: 900;
  stroke-dashoffset: 900;
  animation: offset 2s 0.5s ease forwards;
}

.sub_two line:nth-of-type(2) {
  stroke-dasharray: 900;
  stroke-dashoffset: 900;
  animation: offset 2s 0.55s ease forwards;
}

.sub_two line:nth-of-type(3) {
  stroke-dasharray: 900;
  stroke-dashoffset: 900;
  animation: offset 2s 0.6s ease forwards;
}

.sub_one polyline:nth-of-type(1) {
  stroke-dasharray: 2700;
  stroke-dashoffset: 2700;
  animation: offset 1.5s 1s ease forwards;
}

.sub_one polyline:nth-of-type(2) {
  stroke-dasharray: 2700;
  stroke-dashoffset: 2700;
  animation: offset 1.5s 1.05s ease forwards;
}

.sub_one polyline:nth-of-type(3) {
  stroke-dasharray: 2700;
  stroke-dashoffset: 2700;
  animation: offset 1.5s 1.1s ease forwards;
}

@keyframes zoom_in {
  100% {
    transform: scale(1);
  }
}

.feat {
  transform-origin: 450px 450px;
  transform: scale(0.75);
  animation: zoom_in 3s 0.5s ease-out forwards;
}
