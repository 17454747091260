.customNumberInput::-webkit-inner-spin-button,
.customNumberInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customNumberInput input:focus {
  outline: none !important;
}

.customNumberInput button:focus {
  outline: none !important;
}
